<template>
  <div class="decoration-container">
    <!--  列表  -->
    <template v-if="viewType === 'list'">
      <div class="constructionBox">
        <search-box ref="search" @change="research" @export="_export"></search-box>

        <div class="contentBox">
          <div class="contentTool">
            <el-button @click="add" type="primary" size="medium">新增</el-button>
          </div>

          <el-table
              size="medium"
              class="contentTable"
              @selection-change="handleSelectionChange"
              :data="tableData">
            <el-table-column
                type="selection"
                :selectable="selectAble">
            </el-table-column>
            <el-table-column
                prop="floorName"
                label="楼层">
            </el-table-column>
            <el-table-column
                prop="shopName"
                label="店铺">
            </el-table-column>
            <el-table-column
                prop="businessName"
                label="业态">
            </el-table-column>
            <el-table-column
                prop="creatorName"
                label="提交人">
            </el-table-column>
            <el-table-column
                prop="submitTime"
                label="提交时间">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态">
              <template slot-scope="scope">
                <span class="status" :class=[$reconava.getStatusClass(scope.row.status)] >
                  {{$reconava.getStatusName(scope.row.status)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createDate"  label="操作">
              <template slot-scope="scope">
                <span @click="see(scope.row)" class="blue mgr10">查看</span>
                <span class="mgr10 blue" v-if="(scope.row.status===6||scope.row.status===3)&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)"  @click="editData(scope.row.id)">编辑</span>
                <!--     提交但是还没被审核的数据可以撤销        -->
                <span class="mgr10 blue" v-if="scope.row.status===102&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="repeal(scope.row.id)">撤销</span>
                <!--     驳回的数据可以终止        -->
                <span class="mgr10 blue" v-if="scope.row.status===3&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="terminate(scope.row.id)">终止</span>
                <!--     审批通过后，可以打印详情           -->
                <span class="mgr10 blue" v-if="scope.row.status===4" @click="_print(scope.row.id)">打印</span>
              </template>
            </el-table-column>
          </el-table>
          <ReconovaPagination class="pagination"
                              v-if="tableData.length > 0"
                              @paginationChange="paginationChange"
                              :page-no="search.pageNum"
                              :page-size="search.pageSize"
                              :total="total"
          >
          </ReconovaPagination>
        </div>

      </div>
    </template>
    <!--  详情  -->
    <template v-else-if="viewType === 'see'">
      <check-detail @cancel="viewType = 'list'" :id="currentId" type="see"></check-detail>
    </template>
    <!--  新增/编辑申请  -->
    <template v-else>
      <check-add @cancel="cancel" :type-id="typeId" :edit-id="editId"></check-add>
    </template>
  </div>
</template>

<script>
import SearchBox from "./searchBox";
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import {exportDecration, getCheckList} from "@/services/check";
import CheckDetail from "./formDetail/checkDetail";
import CheckAdd from "./formDetail/checkAdd";
import {onDownload, operatorDialog} from "@/utils/utils";
export default {
  name: "construction",
  components: {CheckAdd, CheckDetail, ReconovaPagination, SearchBox},
  data() {
    return {
      currentId:"",
      viewType:"list",//list列表 & add新增 & see查看
      search:{pageNum:1, pageSize:20,typeId:1},
      tableData:[],
      total:0,
      ids:[], //提交申请的id数组
      typeId: this.$store.state.DECORATION.CONSTRUCTION_TYPEID,
      editId:0
    }
  },
  methods: {
    cancel(flag){
      this.viewType = 'list';
      if (!flag)return;
      this.search.pageNum  = 1;
      //展示列表时需要等search内容渲染结束后再请求
      this.$nextTick(()=>{
        this.getData()
      })
    },

    add(){
      this.viewType = 'add';
      this.editId = 0
    },

    //  编辑
    editData(id){
      this.viewType = 'add';
      this.editId = id;
    },

    //table行是否可被选
    selectAble(row) {
      return row.status === 0
    },
    handleSelectionChange(data){
      this.ids = [];
      data.map(item => {
        this.ids.push(item.id)
      })
    },
    research(form){
      this.search.pageNum = 1;
      this.getData()
    },
    //获取列表数据
    getData(){
      let query = Object.assign(this.search,this.$refs.search.getSearch());
      getCheckList(query).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total
      })
    },
    //查看
    see(row){
      this.currentId = row.id+"";
      this.viewType = 'see'
    },

    // 撤销
    repeal(id){
      operatorDialog(this,'理由','撤销',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.repealMyApply(param).then(response=>{
          this.$message.success('已撤销');
          this.getData()
        })
      })
    },

    // 终止
    terminate(id){
      operatorDialog(this,'理由','终止',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.terminateMyApply(param).then(response=>{
          this.$message.success('已终止');
          this.getData()
        })
      })
    },

    //翻页
    paginationChange(type, val) {
      if (type === "pageNo") {
        this.search.pageNum = val;
      } else {
        this.search.pageNum = 1;
        this.search.pageSize = val;
      }
      this.getData()
    },

    _export(){
      exportDecration(Object.assign({pageNum:1,pageSize:10000,typeId:this.typeId},this.$refs.search.getSearch())).then(({headers, data})=>{
        onDownload(headers, data);
      })
    },

    _print(id){
      this.$router.push({path:'/businessModule/examine/decoration-print',query:{id}})
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped lang="scss">
@import "css/exame.scss";

</style>
